<script>
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const server = urlParams.get("s") || "miguelra.com";

  let date = new Date().getFullYear();
</script>

<main>
  <div class="brand">Política de Cookies</div><br />
  <div class="container">
    <div class="row">
      <div class="box">
        <div class="col-lg-12">
          <div class="contenido_page">
            <center>
              <h2>¿QUÉ SON LAS COOKIES?</h2>
            </center>
            <hr />
            <p>
              Las cookies son archivos que las páginas web almacenan en el
              navegador del usuario que las visita, necesarias&nbsp; para
              aportar a la navegación web ventajas en la prestación de servicios
              interactivos.
            </p>
            <br />
            <center>
              <h2><strong>Tipos posibles de cookies</strong></h2>
            </center>
            <hr />
            <p>
              <strong>Cookies de sesión:</strong>
              Son un tipo de cookies diseñadas para recabar y almacenar datos
              mientras el usuario accede a una página web, y no queda registrada
              en el disco del usuario.
            </p>
            <p>
              <strong>Cookies persistentes:</strong>
              Son un tipo de cookies en el que los datos siguen almacenados en
              el terminal y pueden ser accedidos y tratados durante un periodo
              definido por el responsable de la cookie, y que puede ir de unos
              minutos a varios años.
            </p>
            <p>Las cookies también pueden ser:</p>
            <p>
              <strong>Propias:</strong>
              son cookies generadas por la propia página web que se está
              visitando.
            </p>
            <p>
              <strong>De terceros:</strong>
              son cookies que se reciben al navegar por esa página web, pero que
              han sido generadas por un tercer servicio que se encuentra
              hospedado en ella.
            </p>
            <p><strong>Fines de las cookies</strong></p>
            <p>
              <strong>Fines técnicos:</strong>
              Son necesarias para el funcionamiento de la página web. Son las
              denominadas también estrictamente necesarias. Hacen posible el
              control de tráfico desde el servidor a múltiples usuarios a la
              vez, la identificación y el acceso como usuario del sistema, etc.
            </p>
            <p>
              <strong>Personalización:</strong>
              Hacen posible que cada usuario pueda configurar aspectos como el
              lenguaje en el que desea ver la página web o la configuración
              regional.
            </p>
            <p>
              <strong>Análisis o rendimiento:</strong>
              Permiten medir el número de visitas y criterios de navegación de
              diferentes áreas de la web de forma anónima.
            </p>
            <p>
              <strong>Publicidad:</strong>
              Permiten implementar parámetros de eficiencia en la publicidad
              ofrecida en las páginas web.
            </p>
            <p>
              <strong>Publicidad comportamental:</strong>
              Permiten implementar parámetros de eficiencia en la publicidad
              ofrecida en las páginas web, basados en información sobre el
              comportamiento de los usuarios.
            </p>
            <br />
            <center>
              <h2>Uso de Cookies</h2>
            </center>
            <hr />
            <p>
              <strong><a href="http://www.{server}">www.{server}</a>
                utiliza las siguientes COOKIES:
              </strong>Cookies de análisis, en concreto Cookies de Google
              Analytics, y Cookies publicitarias.
            </p>
            <p>
              <strong>Cookies de análisis:</strong>
              Son aquéllas que permiten al responsable de las mismas, el
              seguimiento y análisis del comportamiento de los usuarios de los
              sitios web a los que están vinculadas. La información recogida
              mediante este tipo de cookies se utiliza en la medición de la
              actividad de los sitios web, aplicación o plataforma y para la
              elaboración de perfiles de navegación de los usuarios de dichos
              sitios, aplicaciones y plataformas, con el fin de introducir
              mejoras en función del análisis de los datos de uso que hacen los
              usuarios del servicio.
            </p>
            <p>
              <strong>Cookies publicitarias:</strong>
              Son aquéllas que permiten la gestión, de la forma más eficaz
              posible, de los espacios publicitarios que, en su caso, el editor
              haya incluido en una página web, aplicación o plataforma desde la
              que presta el servicio solicitado en base a criterios como el
              contenido editado o la frecuencia en la que se muestran los
              anuncios.
            </p>
            <p>
              <strong>Cookies de publicidad comportamental:</strong>
              Son aquéllas que permiten la gestión, de la forma más eficaz
              posible, de los espacios publicitarios que, en su caso, el editor
              haya incluido en una página web, aplicación o plataforma desde la
              que presta el servicio solicitado. Estas cookies almacenan
              información del comportamiento de los usuarios obtenida a través
              de la observación continuada de sus hábitos de navegación, lo que
              permite desarrollar un perfil específico para mostrar publicidad
              en función del mismo.
            </p>
            <p><strong>Estrictamente necesarias</strong></p>
            <p>
              Las Cookies estrictamente necesarias le permiten navegar por la
              página web y usar sus funciones esenciales. Estas cookies no
              recogen ninguna información sobre su navegación que pueda ser
              utilizada por acciones de marketing o para recordar por qué
              páginas ha navegado.
            </p>
            <table
              border="1"
              cellspacing="0"
              cellpadding="0"
              class="table-response">
              <tbody>
                <tr>
                  <td valign="top" width="160">COOKIE</td>
                  <td valign="top" width="160">DURACION</td>
                  <td valign="top" width="160">PROVEEDOR</td>
                  <td valign="top" width="160">DESCRIPCION</td>
                </tr>
                <tr>
                  <td valign="top" width="160">__utma</td>
                  <td valign="top" width="160">Persistente</td>
                  <td valign="top" width="160">Google</td>
                  <td valign="top" width="160">
                    Permiten al servicio de Google Analytics recoger
                    estadísticas anónimas sobre el uso de nuestra web.
                  </td>
                </tr>
                <tr>
                  <td valign="top" width="160">__utmb</td>
                  <td valign="top" width="160">30 minutos</td>
                  <td valign="top" width="160">Google</td>
                  <td valign="top" width="160">
                    Permiten al servicio de Google Analytics recoger
                    estadísticas anónimas sobre el uso de nuestra web.
                  </td>
                </tr>
                <tr>
                  <td valign="top" width="160">__utmc</td>
                  <td valign="top" width="160">sesión</td>
                  <td valign="top" width="160">Google</td>
                  <td valign="top" width="160">
                    Permiten al servicio de Google Analytics recoger
                    estadísticas anónimas sobre el uso de nuestra web.
                  </td>
                </tr>
                <tr>
                  <td valign="top" width="160">__utmz</td>
                  <td valign="top" width="160">6 meses</td>
                  <td valign="top" width="160">Google</td>
                  <td valign="top" width="160">
                    Permiten al servicio de Google Analytics recoger
                    estadísticas anónimas sobre el uso de nuestra web.
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <center>
              <h2><strong>Cómo desinstalar cookies</strong></h2>
            </center>
            <hr />
            <p>
              Si quieres borrar de tu navegador las cookies registradas por
              <a href="http://www.{server}">www.{server}</a>, aquí tienes
              instrucciones para distintos navegadores:
            </p>
            <p>
              <strong>Internet Explorer:</strong>
              <a
                href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies">http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies</a>
            </p>
            <p>
              <strong>Google Chrome:</strong>
              <a
                href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a>
            </p>
            <p>
              <strong>Firefox:</strong>
              <a
                href="http://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored">http://support.mozilla.org/kb/delete-cookies-remove-info-websites-stored</a>
            </p>
            <p>
              <strong>Safari:</strong>
              <a
                href="http://support.apple.com/kb/ph11920">http://support.apple.com/kb/ph11920</a>
              (en inglés)
            </p>
            <ol>
              <li>Entra al menú Safari &gt; Preferencias</li>
              <li>En la sección Privacidad, pulsa el botón Detalles</li>
              <li>
                Selecciona el sitio web que almacena cookies y pulsa Eliminar, o
                pulsa Eliminar Todos para borrar todas las cookies
              </li>
            </ol>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container -->
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <p>Copyright &copy; {date}</p>
        </div>
      </div>
    </div>
  </footer>
</main>
